import useVuelidate from '@vuelidate/core';
import { helpers, minLength, required } from '@vuelidate/validators';
import type { Ref } from 'vue';
import { computed } from 'vue';
import { emailRule } from '~/shared/validation/rules/email-rule';

const fieldRequired = helpers
  .withMessage(({ $params }) => `${$params.label} is required`, required);

const rules = {
  email: {
    fieldRequired: helpers.withParams({ label: 'Email' }, fieldRequired),
    emailRule,
  },
  password: {
    fieldRequired: helpers.withParams({ label: 'Password' }, fieldRequired),
    minLength: helpers.withMessage('Password must be at least 8 characters', minLength(3)),
  },
};

export function useLoginValidation(
  data: Ref<{ email: string, password: string }>,
) {
  const vuelidate = useVuelidate(rules, data, {
    $lazy: false,
    $autoDirty: true,
  });

  const errors = computed(() => ({
    email: vuelidate.value.email.$errors[0]?.$message as string || '',
    password: vuelidate.value.password.$errors[0]?.$message as string || '',
  }));

  const hasErrors = computed(() => vuelidate.value.$invalid);

  const reset = () => {
    vuelidate.value.$reset();
  };

  const trigger = async () => {
    await vuelidate.value.$validate();

    return !vuelidate.value.$invalid;
  };

  return {
    errors,
    hasErrors,
    trigger,
    reset,
  };
}
